/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint max-len: ["error", { "code": 1080 }]*/
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logoutUser } from 'models/actions/userActions';
import { user } from 'models/selectors/userSelector';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import spritesvg from 'sprite.svg';

const UserMenu = ({ token }) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return token ? (
    <div className="usermenu-container">
      <i onClick={handleClick}>
        <svg className="header-icon">
          <use href={`${spritesvg}#user`}></use>
        </svg>
      </i>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        className="user-menu"
        TransitionComponent={Fade}>
        <MenuItem className="userLoggedIn">
          Welcome {loggedInUser?.userLoggedIn?.username}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/changepassword">Change password</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/wishlist">My favorites</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/orders">My orders</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a onClick={() => dispatch(logoutUser())}>Logout</a>
        </MenuItem>
      </Menu>
    </div>
  ) : (
    <Link to="/login" className="user-icon">
      <i>
        <svg
          className="header-icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="24"
          height="24"
          viewBox="0 0 24 24">
          <g transform="scale(0.0234375 0.0234375)">
            <path d="M896 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM725.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z" />
          </g>
        </svg>
      </i>
    </Link>
  );
};

export default UserMenu;
