/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import RelevantProducts from 'components/relevant-products/RelevantProducts';
import SEO from 'components/seo/SEO';
import formatMoney from 'library/formatMoney';
import getPercentage from 'library/getPercentage';
import { addToCart } from 'models/actions/cartActions';
import {
  getProductDetails,
  getRelatedProducts,
  getColorProducts,
  setGeneralLoading,
} from 'models/actions/catalogActions';
import { addProductWishlist } from 'models/actions/wishlistActions';
import {
  singleProduct,
  relatedProducts,
  colorOptions,
} from 'models/selectors/catalogSelectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import spritesvg from 'sprite.svg';

const ProductPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector(singleProduct);
  const relProducts = useSelector(relatedProducts);
  const colOptions = useSelector(colorOptions);

  const {
    productTitle,
    productDescription,
    initialPrice,
    price,
    imgHref,
    code,
    productLargeDescription,
    isNew,
    stock,
  } = product;

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getProductDetails(id));
    dispatch(getRelatedProducts(id));
    dispatch(getColorProducts(id));
  }, [id]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
  };

  const allImgHrefs =
    imgHref?.indexOf('#') !== -1
      ? imgHref
          ?.substr(0, imgHref.lastIndexOf('#'))
          .split('#')
          ?.map((imag) => imag)
      : [imgHref];

  return (
    <div className="productPage content">
      <SEO
        title={`${process.env.REACT_APP_WEBSITE_NAME} ${productTitle}`}
        description={`${process.env.REACT_APP_WEBSITE_NAME} ${productTitle}`}
        name={process.env.REACT_APP_WEBSITE_NAME}
        type="article"
      />
      <div className="row">
        <div className="wrapper">
          <Grid container>
            <Grid item sm={6} xs={12} className="mainProductPhotosContainer">
              {isNew ? <div className="is-new">NEW</div> : null}
              {initialPrice !== 'undefined' &&
                initialPrice > 0 &&
                initialPrice !== price && (
                  <div className="price-container">
                    <span className="discount absolute">
                      {getPercentage(initialPrice, price)}%
                    </span>
                  </div>
                )}
              <Slider {...settings}>
                {allImgHrefs?.map((myImage, index) => (
                  <img
                    key={`${myImage}_${index}`}
                    src={`${process.env.REACT_APP_IMAGES_URL}/${myImage}`}
                    alt={productTitle}
                    title={productTitle}
                  />
                ))}
              </Slider>
            </Grid>
            <Grid item sm={6} xs={12} className="pr0 mainProductRightSection">
              <div className="product-title">
                <h1 className="headerTitle">{productTitle}</h1>
              </div>
              <Typography
                variant="body2"
                component="p"
                className="product-code">
                <span>Code: {code}</span>
              </Typography>

              <div className="price-container for-product-page">
                <div>
                  {initialPrice !== 'undefined' &&
                    initialPrice > 0 &&
                    initialPrice !== price && (
                      <span>{formatMoney.format(initialPrice)}</span>
                    )}
                  {formatMoney.format(price)}
                </div>
              </div>

              <Typography
                className="product-description"
                component="p"
                variant="body1">
                {productDescription}
              </Typography>
              <p className={`in-stock ${stock === 0 ? 'not' : ''}`}>
                <span>{stock > 0 ? 'Available' : 'Out of stock'}</span>
                {stock > 0 ? (
                  <span className="minor-text">({stock} more left)</span>
                ) : (
                  ''
                )}
              </p>
              <div className="product-page-actions">
                <button
                  className="add-to-cart"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(addToCart(product));
                  }}>
                  <Tooltip title="Add to cart">
                    <svg className="header-icon">
                      <use href={`${spritesvg}#cart`}></use>
                    </svg>
                  </Tooltip>
                </button>
                <IconButton
                  className="product-action"
                  aria-label="add to favorites"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(addProductWishlist(id));
                  }}>
                  <Tooltip title="Add to favorites">
                    <i className="icon-heart-empty" />
                  </Tooltip>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {productLargeDescription && (
        <div className="row">
          <div className="wrapper">
            <div className="section-title-small">DESCRIPTION</div>
            <div
              dangerouslySetInnerHTML={{ __html: productLargeDescription }}
            />
          </div>
        </div>
      )}
      {colOptions?.length > 0 && (
        <div className="row">
          <div className="wrapper">
            <RelevantProducts
              expandable
              title="COLOR OPTIONS"
              products={colOptions}
            />
          </div>
        </div>
      )}
      {relProducts?.length > 0 && (
        <div className="row">
          <div className="wrapper">
            <RelevantProducts
              expandable={false}
              title="RELATIVE PRODUCTS"
              products={relProducts}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
