/* eslint-disable react-hooks/exhaustive-deps */
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import MiniCart from 'components/cart/MiniCart';
import MainMenu from 'components/main-menu/MainMenu';
import Search from 'components/searchHeader/Search';
import UserMenu from 'components/usermenu/UserMenu';
import { withToggle } from 'library';
import { invalidateExpiredCoupons } from 'models/actions/cartActions';
import { getLogo } from 'models/actions/homeActions';
import { logo } from 'models/selectors/homeSelectors';
import { token } from 'models/selectors/userSelector';
import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // OUR_TRACKING_ID

const Header = ({ cookies, toggleValue, setToggleValue }) => {
  const dispatch = useDispatch();
  const userToken = useSelector(token);

  const logoImage = useSelector(logo);
  const location = useLocation();

  useEffect(() => {
    dispatch(getLogo());
    dispatch(invalidateExpiredCoupons());
  }, []);

  useEffect(() => {
    if (
      cookies.get('CookieConsent') !== null &&
      cookies.get('CookieConsent') !== undefined &&
      cookies.get('CookieConsent') === 'true'
    ) {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  }, [location.pathname]);

  return (
    <Grid
      container
      className={`headerContainer ${
        location.pathname === '/' ? 'home-page-header' : ''
      }`}>
      <Grid item xs={3}>
        <div className="menu-icon-align" onClick={setToggleValue('top', true)}>
          <span className="header-icon">
            <i />
            <i />
            <i />
          </span>
          <span>Menu</span>
        </div>
        <Drawer
          anchor="top"
          open={toggleValue?.top}
          onClose={setToggleValue('top', false)}>
          <MainMenu setToggleValue={setToggleValue} />
        </Drawer>
      </Grid>
      <Grid item xs={6} className="logo">
        <Link to="/">
          <img
            src={`${process.env.REACT_APP_IMAGES_URL}/${logoImage?.data?.name}`}
            alt={logoImage?.data?.name}
            className="logo-image"
          />
        </Link>
      </Grid>
      <Grid item xs={3}>
        <div className="headerActions">
          <Search />

          <MiniCart />
          <UserMenu token={userToken} />
        </div>
      </Grid>
    </Grid>
  );
};

export default compose(withToggle, withCookies)(Header);
