import makeRequest from 'library/makeRequest';
import { toggleShowAlert } from 'models/actions/alertActions';
import {
  getCart,
  setCart,
  addToCart,
  removeItemFromCart,
  updateCartItemTotal,
  navigateBackToCart,
  getAvailableCoupons,
  setAvailableCoupons,
  applyCouponInCart,
  setValidityOfCoupon,
  invalidateExpiredCoupons,
  leaveCartAsIs,
  setCouponDiscount,
  setCouponEmail,
} from 'models/actions/cartActions';
import { setGeneralLoading } from 'models/actions/catalogActions';
import { setUpdatedProducts } from 'models/actions/checkoutActions';
import { ofType, combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, concatMap, map, withLatestFrom } from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';

const getAvailableCouponsEpic = (action$) =>
  action$.pipe(
    ofType(getAvailableCoupons.type),
    mergeMap(() =>
      from(makeRequest('availablecoupons', 'GET', '')).pipe(
        concatMap((payload) => [
          setAvailableCoupons(payload),
          setGeneralLoading(false),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const applyCouponInCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(applyCouponInCart.type),
    withLatestFrom(state$),
    mergeMap(
      ([
        ,
        {
          cartReducer: { couponUsed },
          checkoutReducer: {
            billingInfo: { email },
          },
        },
      ]) =>
        from(
          makeRequest(
            `availableCoupons/validity?code=${couponUsed}&email=${email}`,
            'GET',
            '',
          ),
        ).pipe(
          concatMap(({ validCoupon, couponDiscount, email }) => {
            if (!validCoupon) {
              return [
                setValidityOfCoupon({}),
                setCouponDiscount(0),
                setCouponEmail(email),
                toggleShowAlert({
                  message: 'Coupon is not valid',
                  show: true,
                  type: 'error',
                }),
              ];
            }

            return [leaveCartAsIs(), setCouponDiscount(couponDiscount)];
          }),
          catchErrorOperator(true),
        ),
    ),
  );

const invalidateExpiredCouponsEpic = (action$) =>
  action$.pipe(
    ofType(invalidateExpiredCoupons.type),
    mergeMap(() =>
      from(makeRequest(`availableCoupons/expire`, 'POST', '')).pipe(
        concatMap(() => [getAvailableCoupons()]),
        catchErrorOperator(true),
      ),
    ),
  );

// TODO - NOT USED AT THE MOMENT
const getCartEpic = (action$) =>
  action$.pipe(
    ofType(getCart.type),
    mergeMap(() =>
      from(makeRequest('cart', 'GET', '')).pipe(
        concatMap((payload) => [
          setCart(payload),
          toggleShowAlert({ message: '', show: false, type: 'error' }),
        ]),
        catchErrorOperator(false),
      ),
    ),
  );

const addToCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(addToCart.type),
    withLatestFrom(state$),
    concatMap(
      ([
        { payload },
        {
          cartReducer: { cart },
        },
      ]) => {
        const {
          productDescription,
          productId,
          price,
          productTitle,
          initialPrice,
          imgHref,
        } = payload;

        const productAlreadyExistsInCart =
          cart?.findIndex((item) => item?.productId === productId) >= 0;

        let newCart = [];

        if (!productAlreadyExistsInCart) {
          newCart = [
            ...cart,
            {
              productTitle,
              productId,
              productDescription,
              total: 1,
              imgHref,
              price,
              initialPrice,
              totalPrice: price,
            },
          ];
        } else {
          newCart = cart?.map((cartItem) => {
            return cartItem.productId !== productId
              ? { ...cartItem }
              : {
                  ...cartItem,
                  price,
                  imgHref,
                  initialPrice,
                  total: Number(Number(cartItem.total) + 1),
                  totalPrice: Number(
                    (Number(cartItem?.total) + 1) * Number(cartItem?.price),
                  ),
                };
          });
        }

        return [
          setCart(newCart),
          toggleShowAlert({
            message: `Product added to cart.`,
            type: 'success',
            show: true,
          }),
        ];
      },
    ),
  );

const removeItemFromCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeItemFromCart.type),
    withLatestFrom(state$),
    concatMap(
      ([
        { payload },
        {
          cartReducer: { cart },
        },
      ]) => {
        const productId = payload?.id;
        const isCheckout = payload?.checkout;

        const newCart = cart?.filter((item) => item?.productId !== productId);

        if (isCheckout) {
          if (newCart.length === 0) {
            return [
              setCart(newCart),
              setUpdatedProducts(false),
              navigateBackToCart(),
            ];
          }

          if (
            newCart.length > 0 &&
            newCart.filter((pr) => pr.total === 0)?.length === 0
          ) {
            return [setCart(newCart), setUpdatedProducts(false)];
          }
        }

        return [setCart(newCart)];
      },
    ),
  );

const updateCartItemTotalEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateCartItemTotal.type),
    withLatestFrom(state$),
    map(
      ([
        { payload },
        {
          cartReducer: { cart },
        },
      ]) => {
        const { total, productId } = payload;

        const newCart = cart?.map((item) => {
          return item?.productId !== productId
            ? { ...item }
            : { ...item, total: Number(total), totalPrice: total * item.price };
        });

        return setCart(newCart);
      },
    ),
  );

export {
  getCartEpic,
  addToCartEpic,
  removeItemFromCartEpic,
  updateCartItemTotalEpic,
  getAvailableCouponsEpic,
  applyCouponInCartEpic,
  invalidateExpiredCouponsEpic,
};

const epics = combineEpics(
  getCartEpic,
  addToCartEpic,
  removeItemFromCartEpic,
  updateCartItemTotalEpic,
  getAvailableCouponsEpic,
  applyCouponInCartEpic,
  invalidateExpiredCouponsEpic,
);

export default epics;
